<template>
    <div class="list">
        <div v-if='list.length>0'>
            <div class="item" v-for='(item,index) in list' :key='index' @click='(check == 1 || item.children) && navigator(item.deptCode)'>
                <div class="img">
                    <img :src="imgName" alt="">
                </div>
                <div class="info">
                    <div class="name overnowrap" :class="check == 0 ?'lh40':'lhn'">{{item.deptName}}</div>
                    <!-- <div class="time" v-if='check == 0 && difference == 1'>{{item.courtName}}</div> -->
                    <div class="time" v-if='check == 1'>{{item.creatorTime}}</div>
                </div>
                <div class="right" v-if='check == 0'>{{item.count}}个<span class="symbol"  v-if='check == 0 && item.children'>&#155</span></div>
                <div class="right" v-if='check == 1'>￥{{item.feeMoney > 0 ?item.feeMoney.formatMoney():"0.00"}}<span class="symbol"  v-if='check == 1'>&#155</span></div>
                <div class="clear"></div>
                <div class="line-wrapper" v-if='check == 0 && item.children'>
                    <div v-if='item.children.length > 3'>
                        <div class="line" v-for='(itemv,index1) in item.children.slice(0,3)' :key='index1'>
                            <div class="l-left">{{itemv.deptName}}</div>
                            <div class="l-right">{{itemv.count}}个</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="line" v-for='(itemv,index1) in item.children' :key='index1'>
                            <div class="l-left">{{itemv.deptName}}</div>
                            <div class="l-right">{{itemv.count}}个</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-else class="no-data">
            <img src="../assets/no-data.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "ItemServiceList",
    props: {
        check: Number,
        difference: Number,
        imgName:String,
        list:Array,
    },
    methods:{
        navigator(deptCode){
            this.$emit('itemClick',deptCode)
        },
     
    },
    mounted() {
      
    },
};
</script>